// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCr6OA7_SkasTBvK2mUnONLsxhNy9e5MQs",
    authDomain: "analicebarreto-fisio.firebaseapp.com",
    databaseURL: "https://analicebarreto-fisio.firebaseio.com",
    projectId: "analicebarreto-fisio",
    storageBucket: "analicebarreto-fisio.appspot.com",
    messagingSenderId: "1005945049148",
    appId: "1:1005945049148:web:f41ea956b74410c7f7f339",
    measurementId: "G-1FHBE59PMB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);