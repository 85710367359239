import React, { useState, useEffect } from 'react';
import { Accordion, Modal, Row, Carousel, Card, Button, Navbar, Nav, Container } from 'react-bootstrap';

import { Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import firebase from './components/firebase'
import { getAnalytics, logEvent } from "firebase/analytics";

import './App.css';

function App() {
  const [modalServicoVentosa, setModalServicoVentosa] = useState(false);
  const [modalServicoRpg, setModalServicoRpg] = useState(false);
  const [modalServicoPilates, setModalServicoPilates] = useState(false);
  const [modalServicoAcupuntura, setModalServicoAcupuntura] = useState(false);
  const [modalServicoEletroterapia, setModalServicoEletroterapia] = useState(false);
  const [modalServicoLaser, setModalServicoLaser] = useState(false);
  const [modalServicoDomicilio, setModalServicoDomicilio] = useState(false);
  const [modalServicoEmpresa, setModalServicoEmpresa] = useState(false);
  const [modalServicoServicos, setModalServicoServicos] = useState(false);

  useEffect(() => {
    // checkLogin()
    return () => {
      // checkLogin()
      const analytics = getAnalytics();
      logEvent(analytics, 'select_content', {
        content_type: 'image',
        content_id: 'P12453',
        items: [{ name: 'Kittens' }]
      });
      console.log('OK');
    }
  }, [])
  return (
    <div className="App" id="topo">

      <header className="App-header">

        {/* Nav Bar */}
        <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark" >
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          <div className="container">
            <div className="navbar-brand">
              {/* <Navbar.Brand className="px-0 px-lg-3 rounded js-scroll-trigger" href="">Analice Barreto</Navbar.Brand> */}
              <Nav.Link className="px-0 px-lg-3 rounded js-scroll-trigger" to="topo" smooth={true}>Analice Barreto</Nav.Link>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto text-center">
                <Nav.Link href="#topo">Home</Nav.Link>
                <Nav.Link href="#servicos">Serviços</Nav.Link>
                <Nav.Link href="#about">Sobre Nós</Nav.Link>
                <Nav.Link href="#agendamento">Agendamento</Nav.Link>
                <Nav.Link href="#localizacao">Localização</Nav.Link>
              </Nav>
              {/* <Nav>
            <Nav.Link className="px-0 px-lg-3 rounded js-scroll-trigger" href="#deets">More deets</Nav.Link>
            <Nav.Link className="px-0 px-lg-3 rounded js-scroll-trigger" eventKey={2} href="#memes">Dank memes</Nav.Link>
          </Nav> */}
            </Navbar.Collapse>
          </div>
        </Navbar>


        {/* apresentacao */}
        <div className="masthead bg-primary text-white text-center">
          <div className="container bg-primary d-flex align-items-center flex-column">
            <p className="masthead-heading text-uppercase mb-0">Consultório de Fisioterapia e Reeducação Postural</p>
            <Carousel className="container">
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im0.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im1.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im2.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im3.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im4.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im5.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im6.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im7.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im8.png" alt="" />
              </Carousel.Item>

              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/apresentacao/im9.png" alt="" />
              </Carousel.Item>


            </Carousel>
          </div>
        </div>

        {/* Servicos */}
        {/* <div id="servicos" className="masthead d-flex bg-primary2 justify-content-around"> */}
        <div id="servicos" className="masthead bg-primary2">
          <div className="container">

            {/* <!-- About Section Heading --> */}
            <h2 className="page-section-heading text-center text-uppercase text-white">Serviços</h2>
          </div>
          <div className="container d-flex align-items-center flex-row row  justify-content-around}">
            <Row>
              {/* VENTOSATERAPIA */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img className="card-img" variant="top" onClick={() => setModalServicoVentosa(true)} src="img/servicos/ventosa/2.png" />
                <Card.Body>
                  <Card.Title>VENTOSATERAPIA</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoVentosa(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

              {/* RPG */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img className="card-img" variant="top" onClick={() => setModalServicoRpg(true)} src="img/servicos/rpg/1.png" />
                <Card.Body>
                  <Card.Title>RPG</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoRpg(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

              {/* MAT PILATES */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img className="card-img" variant="top" onClick={() => setModalServicoPilates(true)} src="img/servicos/pilates/1.png" />
                <Card.Body>
                  <Card.Title>MAT PILATES</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoPilates(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

              {/* ACUPUNTURA */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img className="card-img" variant="top" onClick={() => setModalServicoAcupuntura(true)} src="img/servicos/acupuntura/1.png" />
                <Card.Body>
                  <Card.Title>ACUPUNTURA</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoAcupuntura(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

              {/* ELETROTERAPIA */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img className="card-img" variant="top" onClick={() => setModalServicoEletroterapia(true)} src="img/servicos/eletroterapia/1.png" />
                <Card.Body>
                  <Card.Title>ELETROTERAPIA</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoEletroterapia(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

              {/* LASERTERAPIA */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img className="card-img" variant="top" onClick={() => setModalServicoLaser(true)} src="img/servicos/laserterapia/1.png" />
                <Card.Body>
                  <Card.Title>LASERTERAPIA</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoLaser(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

              {/* DOMICILIO */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img style={{ width: '60%' }} className="card-img" variant="top" onClick={() => setModalServicoDomicilio(true)} src="img/servicos/homecare/1.png" />
                <Card.Body>
                  <Card.Title>ATENDIMENTO DOMICÍLIAR</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoDomicilio(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

              {/* EMPRESARIAL */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img style={{ width: '70%' }} className="card-img" variant="top" onClick={() => setModalServicoEmpresa(true)} src="img/servicos/homecare/2.png" />
                <Card.Body>
                  <Card.Title>ATENDIMENTO EMPRESARIAL</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoEmpresa(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

              {/* SERVICOS */}
              <Card style={{ width: '18rem', color: '#000000' }} className="card-servicos">
                <Card.Img style={{ width: '70%' }} className="card-img" variant="top" onClick={() => setModalServicoServicos(true)} src="img/servicos/servicos/1.png" />
                <Card.Body>
                  <Card.Title>Todos os Serviços</Card.Title>
                  <Button variant="primary" onClick={() => setModalServicoServicos(true)}>Saiba Mais.</Button>
                </Card.Body>
              </Card>

            </Row>

          </div>
        </div>

        {/* <!-- Sobre Section --> */}
        <section className="page-section bg-primary text-white mb-0" id="about">
          <div className="container">

            {/* <!-- About Section Heading --> */}
            <h2 className="page-section-heading text-center text-uppercase text-white">Sobre</h2>


            {/* <!-- About Section Content --> */}
            <div className="row">
              <div className="col-lg-4 ml-auto">
                <p className="lead">Somos uma empresa Amazonense, fundada em 2019. A empresa possui sede e Consultório de Fisioterapia e Reabilitação Postural, situado na Av. Codajás n.584 C1 no bairo Cachoeirinha.</p>
              </div>
              <div className="col-lg-4 mr-auto">
                <p className="lead">Nosso objetivo é levar a população Amazonense um serviço sério, de qualidade, aplicando as melhores técnicas de Fisioterapia existentes com auxílio dos melhores equipamentos, tudo isso a um baixo custo.</p>
              </div>
            </div>

          </div>
        </section>

        {/* <!-- Agendamentos Section --> */}
        <section className="page-section bg-primary2 " id="agendamento">
          <div className="container">

            {/* <!-- Contact Section Heading --> */}
            <h2 className="page-section-heading text-center text-uppercase text-white mb-0">Agendamentos</h2>

            {/* <!-- Contact Section Form --> */}
            <div className="col">
              <div className="col-lg-12 mx-auto text-center">
                <div>Agende sua consulta ou tire suas dúvidas sobre seus sintomas pelo Whatsapp:</div>
                <a href="https://api.whatsapp.com/send?phone=5592991461522&text=Gostaria%20de%20agendar%20uma%20consulta." rel="noopener noreferrer" target="_blank"> 92 99146-1522 </a>
                <div>Ou pelo e-mail:</div>
                <a href="mailto:fisio@analicebarreto.com.br" target="_blank" rel="noopener noreferrer"> fisio@analicebarreto.com.br </a>
              </div>
            </div>
            {/* <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes) --> */}
            <div className="scroll-to-top d-lg-none">
              <a className="js-scroll-trigger d-block text-center text-white rounded" href="#topo">
                <i className="fa fa-chevron-up"></i>
              </a>
            </div>
          </div>
        </section>

        {/* <!-- Localização --> */}
        <footer id="localizacao" className="page-section bg-primary text-center">
          <div className="container">
            <div className="row">

              {/* <!-- Footer Location --> */}
              <div className="col-lg-6 mb-5 mb-lg-0">
                <h4 className="text-uppercase mb-4">Endereço</h4>
                <p className="lead mb-0">Av. Codajás, 584 - Cachoeirinha
                  <br />Manaus, 69065-130</p>
                <div style={{ width: '10px' }}>


                </div>
              </div>

              {/* <!-- Footer Social Icons --> */}
              <div className="col-lg-6 mb-5 mb-lg-0">
                <h4 className="text-uppercase mb-4">Redes Sociais</h4>
                <Link color="inherit" href="https://www.facebook.com/fisioanalicebarreto/">
                  <FacebookIcon fontSize="large"></FacebookIcon>
                </Link>

                <Link color="inherit" href="https://www.instagram.com/fisioanalicebarreto/">
                  <InstagramIcon fontSize='large'></InstagramIcon>
                </Link>

                <Link color="inherit" href="https://api.whatsapp.com/send?phone=5592991461522&text=Gostaria%20de%20agendar%20uma%20consulta.">
                  <WhatsAppIcon fontSize='large'></WhatsAppIcon>
                </Link>
              </div>

            </div>
          </div>
        </footer>

        {/* <!-- Copyright Section --> */}
        <section className="copyright py-4 text-center text-white">
          <div className="container">
            <small>Copyright &copy; jdnetservices.com.br 2022</small>
          </div>
        </section>

      </header>

      {/* Modal Ventosa */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoVentosa} onHide={() => { setModalServicoVentosa(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">Ventosa Terapia</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/ventosa/2.png" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/ventosa/3.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>O que é?</Accordion.Header>
              <Accordion.Body>
                A ventosaterapia é um tipo de tratamento natural no qual são usadas ventosas para melhorar a circulação sanguínea em um local do corpo. Para isso, as ventosas criam um efeito de vácuo, que suga a pele, resultando em um aumento do diâmetro dos vasos sanguíneo no exato local. Como resultado, existe uma maior oxigenação destes tecidos, permitindo a liberação de toxinas do sangue e do músculo com mais facilidade.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Benefícios</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Dores nas Costas;</li>
                  <li>Inchaço nos braços, pernas ou pés;</li>
                  <li>Dor nas articulações;</li>
                  <li>Tratamento da celulite;</li>
                  <li>Aumento da circulação sanguínea local;</li>
                  <li>Eliminação de contraturas musculares e pontos gatilho;</li>
                  <li>Fortalece os vasos sanguíneos;</li>
                  <li>Aumentar a produção de líquido sinovial dentro das articulações;</li>
                  <li>Relaxar e acalmar o corpo e a mente.</li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoVentosa(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal RPG */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoRpg} onHide={() => { setModalServicoRpg(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">RPG</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/rpg/1.png" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/rpg/1.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>O que é?</Accordion.Header>
              <Accordion.Body>
                <p>A técnica de Reeducação Postural Global é baseada em seis posturas específicas que tem como o foco <strong>o alongamento muscular, o trabalho respiratório e o fortalecimento de músculos,</strong> fundamentais para a <strong>melhora do alinhamento postural.</strong>.</p>
                <p>O trabalho de <strong>Sensações Somáticas e Reeducação Postural - SSRP</strong> é uma busca na mudança de postura e no esquema corporal através do alongamento global.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Benefícios</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Melhora da postura;</li>
                  <li>Ganho de flexibilidade;</li>
                  <li>Relaxamento;</li>
                  <li>Consciência corporal;</li>
                  <li>Alívio de dores;</li>
                  <li>Qualidade de vida.</li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoRpg(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Mat Pilates */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoPilates} onHide={() => { setModalServicoPilates(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">Mat Pilates</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/pilates/1.png" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/pilates/1.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>O que é?</Accordion.Header>
              <Accordion.Body>
                <p>O Mat Pilates, são exercícios realizados com o objetivo geral de fortalecer e alongar ao mesmo tempo de forma controlada e coordenada.</p>
                <p>O Mat Pilates é indicado para correção da <strong>má postura, disfunções musculoesqueléticas, populações especiais, atletas, idosos, crianças e etc, problemas respiratórios.</strong>.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Benefícios</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Equilíbrio;</li>
                  <li>Postura;</li>
                  <li>Dores musculoesqueléticos;</li>
                  <li>Consciência corporal;</li>
                  <li>Fortalecimento Muscular;</li>
                  <li>Flexibilidade;</li>
                  <li>Patologias musculoesqueléticas, reumáticas e etc.</li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoPilates(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Acupuntura */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoAcupuntura} onHide={() => { setModalServicoAcupuntura(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">Acupuntura</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/acupuntura/1.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>O que é?</Accordion.Header>
              <Accordion.Body>
                <p>A acupuntura é uma técnica milenar de origem chinesa, que consiste na aplicação de agulhas bem finas, em pontos específicos do corpo, para melhorar a imunidade e tratar problemas emocionais e doenças físicas.</p>
                <p>As técnicas de acupuntura baseiam-se na idéia de que o corpo é composto de energia, acumulada em várias regiões, que são chamados de meridianos. Se o fluxo de energia nestes meridianos estiver desequilibrado provoca inflamações no corpo, causando sintomas de dor, cansaço e fraqueza..</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Benefícios</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Tratar Insônia;</li>
                  <li>Tratar Ansiedade;</li>
                  <li>Alivia dores de cabeça;</li>
                  <li>Reduz dores crônicas;</li>
                  <li>Recuperação de Movimentos após AVC;</li>
                  <li>Reduz problemas intestinais;</li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoAcupuntura(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Eletroterapia */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoEletroterapia} onHide={() => { setModalServicoEletroterapia(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">Eletroterapia</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/eletroterapia/1.png" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/eletroterapia/2.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>O que é?</Accordion.Header>
              <Accordion.Body>
                <p>A eletroterapia consiste no uso de correntes elétricas para a realização de um tratamento fisioterápico. A corrente Tens costuma ser feita para o tratamento de dores no pós operatório, em casos de dores crônicas/agudas como lombalgia, cervicalgia,bursite, por exemplo. Já a corrente Russa tem como objetivo aumento da força muscular e resistência.</p>
                <p>Por fim a corrente Fes que ajuda no fortalecimento muscular em pessoas que não conseguem controlar o movimento , como em casos de sequelas de AVC, ou em atletas para melhorar o rendimento do treino.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Benefícios</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Controle de Dores;</li>
                  <li>Fortalecimento Muscular;</li>
                  <li>Previne Atrofia;</li>
                  <li>Reduz espasmos musculares;</li>
                  <li>Reduz edemas;</li>
                  <li>Auxilia na cicatrização;</li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoEletroterapia(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Laserterapia */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoLaser} onHide={() => { setModalServicoLaser(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">Laserterapia</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/laserterapia/1.png" alt="" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="img-fluid rounded mb-5" src="img/servicos/laserterapia/2.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>O que é?</Accordion.Header>
              <Accordion.Body>
                <p>A Laserterapia tem como efeitos terapêuticos: cicatrização, anti-inflamatório, diminuição do edema e analgesia, e indicado no tratamento de diversos tipos de patologias, algumas delas: lombalgias, tendinopatias, cervicalgia, artrites, fibromialgia, lesões musculares, condromalacia, fascite plantar, pos cirúrgicos, queimaduras ,úlceras de pressão e isquêmicas.</p>
                <p>E ainda pode ser utilizado o laser com a técnica ILIB que tem como finalidade melhorar o metabolismo e assim regredir as doencas. A técnica e indicada nos tratamentos de asma e outros transtornos respiratórios, diabetes, fibromialgia, cardiopatias e alterações cardiovasculares, anemia e RECUPERAÇÃO PÓS TREINO, além de minimizar os processos inflamatórios e promover efeito rejuvenescedor</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Benefícios</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Efeito Analgésico;</li>
                  <li>Anti inflamatório;</li>
                  <li>Estimulante celular;</li>
                  <li>Regeneração e cicatrização de tecidos;</li>
                  <li>Reduz edemas;</li>
                  <li>Auxilia na cicatrização;</li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoLaser(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Homecare */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoDomicilio} onHide={() => { setModalServicoDomicilio(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">Atendimento Domiciliar</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img style={{ width: '30%' }} className="img-fluid rounded mb-5" src="img/servicos/homecare/1.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>O que é?</Accordion.Header>
              <Accordion.Body>
                <p>Atendimento realizado em sua residência.</p>
                <p>Todo atendimento do consultório, realizado no conforto de sua casa.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Benefícios</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Conforto do seu lar;</li>
                  <li>Hora marcada;</li>
                  <li>Envolvimento da família;</li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoDomicilio(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Empresarial */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoEmpresa} onHide={() => { setModalServicoEmpresa(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">Atendimento Empresarial</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img style={{ width: '30%' }} className="img-fluid rounded mb-5" src="img/servicos/homecare/2.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>O que é?</Accordion.Header>
              <Accordion.Body>
                <p>Todo conforto e atendimento do consultório realizado dentro da empresa.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Benefícios</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Evita absenteísmo;</li>
                  <li>Evita gasto com transporte;</li>
                  <li>Atendimento humanizado;</li>
                  <li>Evita processos trabalhistas;</li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoEmpresa(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Serviços */}
      <Modal size="lg" className="servicos-modal modal" show={modalServicoServicos} onHide={() => { setModalServicoServicos(false) }}>
        <Modal.Header className="servicos-modal-title text-secondary text-uppercase mb-0" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="servicos-modal-title text-secondary text-uppercase mb-0">Serviços</h2>
            <Carousel className="container">
              <Carousel.Item>
                <img style={{ width: '30%' }} className="img-fluid rounded mb-5" src="img/servicos/servicos/1.png" alt="" />
              </Carousel.Item>
            </Carousel>
          </div>
          <ul>
            <li>Lordose;</li>
            <li>Cifose;</li>
            <li>Hérnia de Disco;</li>
            <li>Rompimento de Tendões;</li>
            <li>Bursites;</li>
            <li>Dores nas Costas;</li>
            <li>Dores nas Braços;</li>
            <li>Dores nas Pernas;</li>
            <li>Síndrome do Túnel do Carpo;</li>
            <li>Nervo Ciático;</li>
            <li>Entre outros serviços;</li>
          </ul>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={() => { setModalServicoServicos(false) }}>
            <i className="fas fa-times fa-fw"></i>
            Fechar Janela
          </Button>
        </Modal.Footer>
      </Modal>

    </div >
  );
}

export default App;
